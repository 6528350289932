import Content from '@/components/Hub/Content';
import contentData from '@/components/Hub/ContentData';
import Hub from '@/components/Hub/Hub';
import NavBar from '@/components/Navbar';
import { Container, Img, VStack } from '@chakra-ui/react';
import { NextPage } from 'next';
import SEO from '@/components/SEO';
import useTranslation from 'next-translate/useTranslation';
import { useEffect } from 'react';
import GTM from '@/helpers/googleTagManager';

const HomeHubPage: NextPage = () => {
  const { t, lang: language } = useTranslation();

  useEffect(() => {
    GTM.pageView({ page: { name: 'hub/homepage', language } });
  }, [language]);

  return (
    <VStack width="100%">
      <>
        <SEO title={t('seo:hubTitle')} description={t('seo:hubDescription')} />
        <NavBar
          backButton={false}
          showLoginButton={false}
          showRegisterButton={true}
          showAllUsersActions={false}
        />

        <Img
          position="absolute"
          top="0"
          left="50%"
          transform="translateX(-50%)"
          mixBlendMode="lighten"
          src="/goldenPalace/backgrounds/stars-top.webp"
          zIndex={-1}
        />
        <Container padding="0" maxWidth="100%" minH={500}>
          <Hub paddingX="1rem" marginX="auto" maxW="container.xl" />
          <Content
            marginTop={{ base: '3rem', md: '5.25rem' }}
            contentData={contentData}
          />
        </Container>
      </>
    </VStack>
  );
};

HomeHubPage.getInitialProps = async () => {
  return {};
};
export default HomeHubPage;
