import { useAppSettings } from '@/context/AppSettings';
import { toRem } from '@/helpers/toRem';
import useMediaQuery from '@/hooks/useMediaQuery';
import {
  getFullUrlByLicense,
  getHubHomeLinkByLicense,
  isLicenseEnable
} from '@/utils/multiDomains';
import { Box, BoxProps, Button, Text } from '@chakra-ui/react';
import useTranslation from 'next-translate/useTranslation';
import Link from 'next/link';

const Hub = ({ ...props }: BoxProps) => {
  const isSmMediaQuery = useMediaQuery('(min-width: md)');
  const style = isSmMediaQuery ? { aspectRatio: '1/1.5' } : { height: '185px' };
  const getImage = (url: string, ext: string) =>
    isSmMediaQuery ? url + '.' + ext : `${url}-mobile.${ext}`;
  const { t, lang: locale } = useTranslation();
  const appSettings = useAppSettings();
  // const sportSubLink = [
  //   {
  //     name: t('common:newGames'),
  //     link: '',
  //     icon: MessageIcon
  //   },
  //   {
  //     name: t('common:promotions'),
  //     link: '',
  //     icon: WalletHzIcon
  //   },
  //   {
  //     name: t('common:tournaments'),
  //     link: '',
  //     icon: WithdrawIcon
  //   }
  // ];
  const sportSubLink: any[] = [];

  return (
    <Box
      as="section"
      display={['flex', 'flex', 'grid']}
      flexDirection="column"
      gridGap="30px"
      marginTop="6.25rem"
      gridTemplateColumns="repeat(3, auto)"
      {...props}
    >
      {isLicenseEnable('B') ? (
        <Box
          borderRadius={'20px'}
          backgroundColor={'backgroundPrimary.900'}
          position="relative"
          overflow="hidden"
          role={'group'}
          _groupActive={{
            filter: 'brightness(0.4)'
          }}
          _groupHover={{
            filter: 'brightness(0.4)'
          }}
          style={style}
        >
          <Link
            href={getHubHomeLinkByLicense({
              license: 'B',
              locale,
              appSettingsFeatures: appSettings?.features
            })}
            passHref
          >
            <Box as="a">
              <Box zIndex={-1}>
                <Box
                  as="img"
                  src={getImage('/goldenPalace/mrVegas', 'webp')}
                  alt=""
                  width="100%"
                  position="absolute"
                  top="0"
                  left="0"
                  borderRadius="20px"
                  transition={'all .3s ease'}
                  filter={['initial', 'brightness(0.4)']}
                  _groupActive={{
                    filter: 'brightness(0.8)'
                  }}
                  _groupHover={{
                    filter: 'brightness(0.8)'
                  }}
                />
                {/* <Box
                  as="img"
                  src={getImage('/goldenPalace/naughtyWoman', 'webp')}
                  alt=""
                  position="absolute"
                  bottom="-10px"
                  height={isMobile ? '185px' : 'auto'}
                  left={isMobile ? 'auto' : '0'}
                  right={isMobile ? '0' : 'auto'}
                /> */}
              </Box>
              <Box
                position={'absolute'}
                top="50%"
                transform="translateY(-50%)"
                left="0"
                padding="0 1.875rem"
              >
                <Text
                  as="h1"
                  fontWeight={600}
                  fontSize={[toRem(12), toRem(13)]}
                  textTransform="uppercase"
                  color="rgba(255,255,255,.8)"
                  letterSpacing=".1rem"
                  textShadow="0px 4px 10px rgba(0, 0, 0, 0.50)"
                >
                  {t('common:letsPlay')}
                </Text>
                <Text
                  as="h2"
                  marginTop={['0rem', '.5rem']}
                  fontWeight={600}
                  fontSize={['1.75rem', '2.25rem', '2.25rem', '3rem']}
                  lineHeight={['2.625rem', '3rem', '3rem', '3.25rem']}
                  style={{ wordWrap: 'normal' }}
                  whiteSpace="normal"
                  textShadow="0px 4px 10px rgba(0, 0, 0, 0.50)"
                >
                  {t('common:casino')}
                </Text>
                <Button
                  as="span"
                  color={'#fff'}
                  background="customGold"
                  padding={`${toRem(14)} ${toRem(38)}`}
                  fontSize={toRem(18)}
                  fontWeight="600"
                  marginTop={toRem(32)}
                  // href={getFullUrlByLicense('B', locale)}
                  height="auto"
                  display={{ base: 'none', md: 'inline-block' }}
                >
                  {t('common:play')}
                </Button>
              </Box>
            </Box>
          </Link>
        </Box>
      ) : null}

      {isLicenseEnable('F') ? (
        <Box
          borderRadius={'20px'}
          backgroundColor={'backgroundPrimary.900'}
          position="relative"
          overflow="hidden"
          style={style}
          transition={'all .3s ease'}
          role="group"
          _groupActive={{
            transition: 'all .3s ease',
            visibility: 'visible',
            opacity: 1,
            filter: 'brightness(0.4)'
          }}
          _groupHover={{
            transition: 'all .3s ease',
            visibility: 'visible',
            opacity: 1,
            filter: 'brightness(0.4)'
          }}
        >
          <Link
            href={getHubHomeLinkByLicense({
              license: 'F',
              locale,
              appSettingsFeatures: appSettings?.features
            })}
            passHref
          >
            <Box as="a">
              <Box zIndex={-1}>
                {/* <Box
                  as="img"
                  src="/goldenPalace/light.webp"
                  position="absolute"
                  top="0"
                  left="0"
                  zIndex={1}
                ></Box> */}
                <Box
                  as="img"
                  src={getImage('/goldenPalace/hub-sport-bg', 'webp')}
                  alt=""
                  width="100%"
                  position="absolute"
                  top="0"
                  left="0"
                  borderRadius="20px"
                  transition={'all .3s ease'}
                  filter={['initial', 'brightness(0.4)']}
                  _groupActive={{
                    filter: 'brightness(0.8)'
                  }}
                  _groupHover={{
                    filter: 'brightness(0.8)'
                  }}
                />
              </Box>
              <Box
                position={'absolute'}
                top="50%"
                transform="translateY(-50%)"
                left="0"
                padding="0 1.875rem"
              >
                <Text
                  as="h1"
                  fontWeight={600}
                  fontSize={[toRem(12), toRem(13)]}
                  textTransform="uppercase"
                  color="rgba(255,255,255,.8)"
                  letterSpacing=".1rem"
                  textShadow="0px 4px 10px rgba(0, 0, 0, 0.50)"
                >
                  {t('common:letsLive')}
                </Text>
                <Text
                  as="h2"
                  marginTop={['0rem', '.5rem']}
                  fontWeight={600}
                  fontSize={['1.75rem', '2.25rem', '2.25rem', '3rem']}
                  lineHeight={['2.625rem', '3rem', '3rem', '3.25rem']}
                  whiteSpace="normal"
                >
                  <Text
                    as="span"
                    display={['initial', 'block']}
                    style={{ wordWrap: 'normal' }}
                    lineHeight={['1', '2rem', '3.25rem']}
                    textShadow="0px 4px 10px rgba(0, 0, 0, 0.50)"
                  >
                    {t('common:sportsBetting')}
                  </Text>
                </Text>
                <Button
                  as="span"
                  background="customGreen"
                  padding={`${toRem(14)} ${toRem(38)}`}
                  fontSize={toRem(18)}
                  fontWeight="600"
                  marginTop={toRem(32)}
                  // href={getFullUrlByLicense('F', locale)}
                  height="auto"
                  display={{ base: 'none', md: 'inline-block' }}
                  _hover={{
                    background: 'customGreen'
                  }}
                >
                  {t('common:bet')}
                </Button>
              </Box>
            </Box>
          </Link>
        </Box>
      ) : null}

      <Box
        borderRadius={'20px'}
        backgroundColor={'backgroundPrimary.900'}
        position="relative"
        overflow="hidden"
        role="group"
        style={style}
      >
        <Link href={appSettings?.hallsUrl} passHref>
          <Box as="a">
            <Box zIndex={-1}>
              <Box
                as="img"
                src={getImage('/goldenPalace/hub-gaming-bg', 'webp')}
                alt=""
                width="100%"
                position="absolute"
                top="0"
                left="0"
                borderRadius="20px"
                transition={'all .3s ease'}
                filter={['initial', 'brightness(0.4)']}
                _groupActive={{
                  filter: 'brightness(0.8)'
                }}
                _groupHover={{
                  filter: 'brightness(0.8)'
                }}
              />
            </Box>
            <Box
              position={'absolute'}
              top="50%"
              transform="translateY(-50%)"
              left="0"
              padding="0 1.875rem"
            >
              <Text
                as="h1"
                fontWeight={600}
                fontSize={toRem(13)}
                textTransform="uppercase"
                color="rgba(255,255,255,.8)"
                letterSpacing=".1rem"
                textShadow="0px 4px 10px rgba(0, 0, 0, 0.50)"
              >
                {t('common:ourHalls')}
              </Text>
              <Text
                as="h2"
                marginTop={['0rem', '.5rem']}
                fontWeight={600}
                fontSize={['1.75rem', '2.25rem', '2.25rem', '3rem']}
                lineHeight={['2.625rem', '3rem', '3rem', '3.25rem']}
              >
                <Text
                  as={'span'}
                  display={['initial', 'block']}
                  style={{ wordWrap: 'normal' }}
                  whiteSpace="normal"
                  textShadow="0px 4px 10px rgba(0, 0, 0, 0.50)"
                >
                  {t('common:gamingHalls')}
                </Text>
              </Text>
              <Button
                as="span"
                color="black"
                background="white"
                padding={`${toRem(14)} ${toRem(38)}`}
                fontSize={toRem(18)}
                fontWeight="600"
                marginTop={toRem(32)}
                // href={getFullUrlByLicense('MAIN', locale) + 'halls'}
                height="auto"
                display={{ base: 'none', md: 'inline-block' }}
                _hover={{
                  background: 'white'
                }}
              >
                {t('common:visit')}
              </Button>
            </Box>
          </Box>
        </Link>
      </Box>
    </Box>
  );
};
Hub.getInitialProps = async () => {
  return {};
};
export default Hub;
